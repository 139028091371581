import React, { useEffect } from "react";

import styled from "styled-components";

import { green } from "theme/colors";

//STYLES
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${green.default};
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: "Scribble Box";
    font-weight: normal;
    font-style: normal;
    color: white;
    letter-spacing: 1px;
  }
`;
//STYLES

const ImprimirTemplate = (props) => {
  //PROPS
  const { dimensions, setColor } = props;
  //PROPS

  //USEEFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //USEEFFECTS

  return (
    <Wrapper>
      <h1>Brevemente</h1>
    </Wrapper>
  );
};

export default ImprimirTemplate;
